import React from 'react'
import { WrapRootElementNodeArgs } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../../theme/globalStyles'
import { theme } from '../../theme'

const RootWrapper = ({ element }: WrapRootElementNodeArgs) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {element}
  </ThemeProvider>
)

export default RootWrapper;
