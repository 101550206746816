export const theme = {
  fonts: {
    primary: `"Inter", sans-serif`,
    secondary: `"Epilogue", sans-serif`,
  },
  breakpoints: {
    xs: "512px",
    sm: "768px",
    md: "896px",
    lg:  "1152px",
    xl:  "1280px",
  },
  colors: {
    background: "#F9F9F9",
    primaryDark: "#15208A",
    primary: "#3C4FFF",
    primaryHover: "#6271FF",
    primaryLight: "#F5F6FF",
    secondary: "#3BDCC9",
    secondaryLight: "#E4FCFA",
    error: "#F03F5F",
    errorHover: "#D82747",
    errorDark: "#A5122C",
    success: "#17C893",
    info: "#12D9F4",
    black: "#191E28",
    gray0: "#242F45",
    gray1: "#4E586D",
    gray2: "#8993A7",
    gray3: "#C1C8D7",
    gray4: "#E2E6EE",
    gray5: "#F8F9FC",
    white: "#FFFFFF",
    border: 'rgba(0, 0, 0, 0.08)'
  },
};