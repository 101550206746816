import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  body {
    font-family: ${(props) => props.theme.fonts.primary};
    font-size: 14px;
    color: ${props => props.theme.colors.black};
    line-height: 180%;
    letter-spacing: 0;
    background: ${props => props.theme.colors.gray5};
    padding: 0;
    margin: 0;

    &[navigation-state="opened"] {
      @media (max-width: ${props => props.theme.breakpoints.lg}) {
        overflow-y: hidden;
      }
    }
  }

  h1, h2, h3, h4, h5 {
    color: ${props => props.theme.colors.gray0};
    font-family: ${props => props.theme.fonts.secondary};
    line-height: 120%;
    font-weight: 600;
    margin: 0;
  }

  h1 {
    font-size: 40px;

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      font-size: 32px;
    }
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      font-size: 16px;
    }
  }

  p {
    font-family: ${(props) => props.theme.fonts.primary};
    font-size: 14px;
    line-height: 180%;
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;

    &:hover {
      text-decoration: none;
      opacity: 0.6;
    }
  }

  input, textarea {
    font-family: ${(props) => props.theme.fonts.primary};
    font-size: 14px;
  }

  ul li {
    font-size: 14px;
    color: ${props => props.theme.colors.black};
    font-family: ${(props) => props.theme.fonts.primary};
    line-height: 180%;
    font-weight: 400;
  }
`;
