module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"defaultLayouts":{"default":"/Users/pawel/works/www/src/templates/article.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-relative-images","options":{"name":"uploads"}},{"resolve":"gatsby-remark-images","options":{"maxWidth":650,"quality":65,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"static"}},"gatsby-remark-lazy-load"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/pawel/works/www"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto:Bold,400,600&display=swap"]}},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"pl","configPath":"/Users/pawel/works/www/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./i18n/locales","i18nextOptions":{"ns":["translation"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
